<template>
  <div id="services">
    <page-content
      class="service-page-header pb-50px"
      title="Our Digital Service"
      subtitle="End-to-end applications development from business process analysis, technical solutions, to implementation. Creating effective digital products and services is more than the development process. It requires careful consideration, thorough analysis, detailed planning and finally implementation strategy and monitoring"
    />
    <service-list-section />
  </div>
</template>

<script>
const PageContent = () => import("@/components/PageContent.vue");
const ServiceListSection = () => import("./Section/ServiceListSection.vue");

export default {
  name: "Services",
  components: {
    PageContent,
    ServiceListSection
  },
};
</script>